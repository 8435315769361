<template>
  <div class="animated fadeIn">
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <h4 class="mb-3">
        Редактирование транспортного средства ID{{ transportVehiclesId }}
      </h4>
      <div class="form-sections">
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Свидетельство о регистрации автомобиля(СТС)
          </h5>
          <b-row>
            <b-col md="6">
              <b-form-group
                id="STSInputGroup2"
                label="Серия и номер СТС"
                label-for="sts"
              >
                <b-form-input
                  id="sts"
                  v-model.trim="$v.form.STS.$model"
                  type="text"
                  name="sts"
                  :state="chkState('STS')"
                  aria-describedby="input1LiveFeedback2"
                  autocomplete="sts"
                  :disabled="vehicle.status === 1"
                  @focus="rememberPreviousValue($v.form.STS.$model, 'STS')"
                  @blur="checkVehicle($v.form.STS.$model, 'STS')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback2">
                  <span v-if="$v.form.STS.required === false">Это поле обязательно для заполнения</span>
                  <span v-if="$v.form.STS.noWhiteSpace === false">В поле не должно быть пробелов</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="stsScreenInputGroup3"
                label="Лицевая сторона СТС"
                label-for="sts-screen"
              >
                <b-form-file
                  id="sts-screen"
                  v-model.trim="$v.form.stsScreen.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('stsScreen')"
                  name="sts-screen"
                  :disabled="!checkVehicleDone || existingVehicle || vehicle.status === 1"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder=""
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('stsScreen')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback3">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
                <div
                  v-if="form.stsScreen.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.stsScreen.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.stsScreen.file)"
                  >{{ form.stsScreen.file.originalName }}</a>
                </div>
              </b-form-group>
              <b-form-group
                id="stsScreenInputGroup4"
                label="Обратная сторона СТС"
                label-for="sts-screen-back"
              >
                <b-form-file
                  id="sts-screen"
                  v-model.trim="$v.form.stsScreenBack.$model"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  :state="chkState('stsScreenBack')"
                  name="sts-screen"
                  :disabled="!checkVehicleDone || existingVehicle || vehicle.status === 1"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder=""
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  @input="uploadFile('stsScreenBack')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback4">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
                <div
                  v-if="form.stsScreenBack.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.stsScreenBack.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.stsScreenBack.file)"
                  >{{ form.stsScreenBack.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Данные по транспортному средству
          </h5>
          <b-row>
            <b-col>
              <b-form-group>
                <b-form-checkbox
                  v-model="$v.form.foreignRegistrationNumber.$model"
                  :disabled="vehicle.status === 1"
                >
                  Инностранное ТС
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                id="vehicleMakeInputGroup4"
                class="form-group-el-select"
                label="Марка ТС"
                label-for="vehicle-make"
                :state="chkState('vehicleMake')"
                :invalid-feedback="'ГРН не соответсуюет Типу ТС'"
              >
                <el-select
                  v-model="form.vehicleMake.value"
                  class="form-control"
                  :disabled="!checkVehicleDone || existingVehicle || vehicle.status === 1"
                  name="vehicle-make"
                  placeholder="Начните вводить название марки"
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="vehicleMakeRemote"
                  clearable
                  :loading="loading"
                  :state="chkState('vehicleMake')"
                  @change="onVehicleMakeChange(form.vehicleMake.value)"
                >
                  <el-option
                    v-for="item in form.vehicleMake.options"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="registrationNumberInputGroup5"
                label="Государственный регистрационный номер (ГРН)"
                label-for="registration-number"
              >
                <b-form-input
                  id="registration-number"
                  v-model.trim="$v.form.registrationNumber.$model"
                  type="text"
                  name="registration-number"
                  :state="chkState('registrationNumber')"
                  aria-describedby="input1LiveFeedback5"
                  autocomplete="registration-number"
                  :disabled="vehicle.status === 1"
                  @focus="rememberPreviousValue($v.form.registrationNumber.$model, 'registrationNumber')"
                  @blur="checkVehicle($v.form.registrationNumber.$model, 'registrationNumber')"
                />
                <b-form-invalid-feedback id="input1LiveFeedback5">
                  <span v-if="$v.form.registrationNumber.isTsogr === false">Поле принимает типы: А007АА777, АА000177</span>
                  <span v-if="$v.form.registrationNumber.required === false">Поле обязательно для заполнения</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="bodyVolumeInputGroup6"
                label="Объем, т"
                label-for="body-volume"
              >
                <b-form-input
                  id="body-volume"
                  v-model.trim="$v.form.bodyVolume.$model"
                  type="number"
                  name="body-volume"
                  :disabled="true"
                  autocomplete="body-volume"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="bodyWidthInputGroup7"
                label="Ширина кузова"
                label-for="body-width"
              >
                <b-form-input
                  id="body-width"
                  v-model.trim="$v.form.bodyWidth.$model"
                  type="number"
                  name="body-width"
                  :disabled="true"
                  autocomplete="body-width"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="bodyHeightInputGroup8"
                label="Высота кузова"
                label-for="body-height"
              >
                <b-form-input
                  id="body-height"
                  v-model.trim="$v.form.bodyHeight.$model"
                  type="number"
                  name="body-height"
                  :disabled="true"
                  autocomplete="body-height"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="bodyDepthInputGroup9"
                label="Глубина кузова"
                label-for="body-depth"
              >
                <b-form-input
                  id="body-depth"
                  v-model.trim="$v.form.bodyDepth.$model"
                  type="number"
                  name="body-depth"
                  :disabled="true"
                  autocomplete="body-depth"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="weightAllowedInputGroup10"
                label="Грузоподъемность, т"
                label-for="weight-allowed"
              >
                <b-form-input
                  id="weight-allowed"
                  v-model.trim="$v.form.weightAllowed.$model"
                  type="number"
                  name="weight-allowed"
                  :disabled="true"
                  autocomplete="weight-allowed"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="vehicleTypeGroup10"
                class="form-group-el-select"
                label="Тип ТС"
                label-for="vehicle-type"
              >
                <el-select
                  id="vehicle-type"
                  v-model="$v.form.vehicleType.id.$model"
                  class="form-control"
                  name="vehicle-type"
                  placeholder=""
                  clearable
                  :loading="loading"
                  :disabled="true"
                >
                  <el-option
                    v-for="item in vehicleTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="6"
            >
              <b-form-group
                id="bodyTypeGroup11"
                class="form-group-el-select"
                label="Тип кузова"
                label-for="body-type"
              >
                <el-select
                  id="body-type"
                  v-model="$v.form.bodyType.id.$model"
                  class="form-control"
                  name="body-type"
                  placeholder=""
                  clearable
                  :loading="loading"
                  :disabled="!checkVehicleDone || existingVehicle || !form.vehicleType.id || vehicle.status === 1"
                >
                  <el-option
                    v-for="item in bodyTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Владелец транспортного средства
          </h5>
          <b-row>
            <b-col>
              <b-form-group id="isOwnGroup12">
                <b-form-checkbox
                  id="is-own"
                  v-model="$v.isOwn.$model"
                  name="is-own"
                  description="Отметьте это поле, только в случае точного совпадения Вашей организации с Собственником ТС указанным в СТС"
                  :disabled="!checkVehicleDone || existingVehicle || vehicle.status === 1"
                >
                  Собственное ТС
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="!isOwn"
              md="12"
            >
              <b-form-group
                id="contractorInputGroup5"
                class="form-group-el-select"
                label="Владелец"
                label-for="contractor"
              >
                <el-select
                  v-model="owner.value"
                  class="form-control"
                  name="owner"
                  placeholder="Начните вводить название организации"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="contractorRemote"
                  :loading="loading"
                  :disabled="!checkVehicleDone || existingVehicle || vehicle.status === 1"
                >
                  <el-option
                    v-for="item in owner.options"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Водитель выполняющий рейсы на данном ТС
          </h5>
          <p>Укажите водителя, который будет выполнять рейсы на данном траспортном средстве.</p>
          <div class="custom-fieldset">
            <b-row>
              <b-col md="12">
                <b-form-group
                  class="form-group-el-select"
                  :invalid-feedback="'Это поле обязательно для заполнения'"
                  :state="chkState('legitDriver')"
                >
                  <el-select
                    v-model="$v.form.legitDriver.$model"
                    clearable
                    placeholder="Укажите водителя"
                    class="form-control"
                    :class="{'is-valid-error': $v.form.legitDriver.$error, 'is-valid-success': $v.form.legitDriver.required}"
                    name="responsible"
                    filterable
                    :state="chkState('legitDriver')"
                    :remote="true"
                    :remote-method="contractorsRoutesLegitDriversList"
                  >
                    <el-option
                      v-for="item in legitDrivers.options"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    />
                  </el-select>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="$v.form.$invalid || loading || !legitDrivers.value"
          :class="{'loading' : loading}"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {validation, isTsogr, noWhiteSpace} from '../../components/mixins/validation';
import {required, minLength, email} from 'vuelidate/lib/validators';
import notifications from '../../components/mixins/notifications';
import {
  contractorsTransportVehicleRead,
  contractorsTransportVehicleUpdate,
  contractorsRelatedOwnersList,
  contractorsRoutesLegitDriversList,
  checkContractorsVehicleWithStsAndRegistrationNumber,
  vehiclesModelRead,
  vehicleModelsList,
  vehicleModelsTypesList,
  vehicleModelsBodyTypesList,
  documentCreate,
  downloadFile,
} from '../../services/api';

export default {
  name: 'TransportVehiclesEdit',
  mixins: [validationMixin, notifications],
  props: ['transportVehiclesId'],
  data() {
    return {
      vehicleIsOwn: false,
      vehicle: {
        id: null,
        name: '',
        STS: '',
        registrationNumber: '',
        status: null,
      },
      checkVehicleDone: true,
      existingVehicle: false,
      vehicleTypes: [{value: null, text: 'Тип ТС'}],
      bodyTypes: [{value: null, text: 'Тип кузова'}],
      vehicleTypeWithOutBodyId: null,
      isOwn: false,
      owner: {
        options: [],
        value: '',
      },
      legitDrivers: {
        options: [],
        value: '',
      },
      form: {
        STS: '',
        stsScreen: {},
        stsScreenBack: {},
        vehicleMake: {
          isVehicleTypeForOgr: this.isVehicleTypeForOgr,
          value: '',
        },
        registrationNumber: '',
        bodyVolume: '',
        weightAllowed: '',
        bodyHeight: '',
        bodyWidth: '',
        bodyDepth: '',
        brand: '',
        vehicleType: {
          id: null,
        },
        bodyType: {
          id: null,
        },
        foreignRegistrationNumber: false,
        legitDriver: '',
      },
      fieldsTriggeringCheckVehicleOldValues: {
        STS: '',
        registrationNumber: '',
      },
      feedBack: 'secondary',
      loading: false,
    };
  },
  computed: {
    formStr() {
      const calculationFormData = {};
      calculationFormData.vehicle = {};
      calculationFormData.isOwn = this.isOwn;
      calculationFormData.vehicle.id = this.vehicle.id;
      if (this.vehicle.status === 1) {
        calculationFormData.vehicle.name = this.vehicle.name;
      } else {
        calculationFormData.vehicle.name = this.form.brand + ' ' + this.form.registrationNumber;
      }
      calculationFormData.vehicle.registrationNumber = this.form.registrationNumber;
      calculationFormData.vehicle.certificateRegistrationVehicleSeriesNumber = this.form.STS;
      if (this.form.stsScreen && this.form.stsScreen.file) {
        calculationFormData.stsScreen = this.form.stsScreen;
      }
      if (this.form.stsScreenBack && this.form.stsScreenBack.file) {
        calculationFormData.stsScreenBack = this.form.stsScreenBack;
      }
      if (this.form.vehicleMake.value) {
        calculationFormData.vehicle.model = {id: this.form.vehicleMake.value};
      }
      if (this.form.bodyType.id !== null) {
        calculationFormData.vehicle.bodyType = {id: this.form.bodyType.id};
      }
      calculationFormData.vehicle.foreignRegistrationNumber = this.form.foreignRegistrationNumber;
      if (this.isOwn === false && this.owner.value) {
        calculationFormData.contractorOwner = {id: this.owner.value};
        calculationFormData.vehicle.contractorOwner = {id: this.owner.value};
      }
      if (this.legitDrivers.value) {
        calculationFormData.contractorDriver = {id: this.form.legitDriver};
      }
      return calculationFormData;
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations() {
    const t = {
      form: {
        STS: {
          required,
          noWhiteSpace,
        },
        stsScreen: {
          required,
        },
        stsScreenBack: {
          required,
        },
        vehicleMake: '',
        bodyVolume: '',
        weightAllowed: '',
        bodyHeight: '',
        bodyWidth: '',
        bodyDepth: '',
        brand: '',
        vehicleType: {
          id: '',
        },
        bodyType: {
          id: '',
        },
        foreignRegistrationNumber: '',
        legitDriver: {
          required,
        },
      },
      isOwn: '',
    };
    if (this.form.foreignRegistrationNumber) {
      t.form.registrationNumber = {
        required,
      };
    } else {
      t.form.registrationNumber = {
        isTsogr,
      };
    }
    return t;
  },
  mounted() {
    this.vehicleModelsTypesList();
    this.vehicleModelsBodyTypesList();
    this.contractorsRoutesLegitDriversList();
    this.contractorsTransportVehicleRead(this.$store.state.user.ownerId, this.transportVehiclesId);
  },
  methods: {
    downloadFile,
    clearForm() {
      this.form.STS = '';
      this.form.stsScreen = null;
      this.form.stsScreenBack = null;
      this.form.vehicleMake = {options: [], value: ''};
      this.form.registrationNumber = '';
      this.form.bodyVolume = '';
      this.form.weightAllowed = '';
      this.form.bodyHeight = '';
      this.form.bodyWidth = '';
      this.form.bodyDepth = '';
      this.form.brand = '';
      this.form.vehicleType.id = null;
      this.form.bodyType.id = null;
      this.isOwn = false;
      this.form.foreignRegistrationNumber = false;
    },
    clearFormBeforeCheckVehicle() {
      this.form.stsScreen = null;
      this.form.stsScreenBack = null;
      this.form.vehicleMake = {options: [], value: ''};
      this.form.bodyVolume = '';
      this.form.weightAllowed = '';
      this.form.bodyHeight = '';
      this.form.bodyWidth = '';
      this.form.bodyDepth = '';
      this.form.brand = '';
      this.form.vehicleType.id = null;
      this.form.bodyType.id = null;
      this.isOwn = false;
    },
    rememberPreviousValue(value, input) {
      this.fieldsTriggeringCheckVehicleOldValues[input] = value;
    },
    async checkVehicle(value, input) {
      if (this.fieldsTriggeringCheckVehicleOldValues[input] !== value) {
        if (this.form.STS !== this.vehicle.STS || this.form.registrationNumber !== this.vehicle.registrationNumber) {
          if (this.form.STS && this.form.registrationNumber) {
            this.loading = true;
            this.checkVehicleDone = false;
            const response = await checkContractorsVehicleWithStsAndRegistrationNumber(this.$store.state.user.ownerId, this.form.STS, this.form.registrationNumber);
            if (response && response.status === 200) {
              if (response.data) {
                this.checkVehicleDone = true;
                this.existingVehicle = true;
                this.showInfo('Данное транспортное средство уже существует');
                this.form.vehicleMake.options = [];
                this.form.vehicleMake.options.push({
                  value: response.data.model.id,
                  text: response.data.model.name + (response.data.model.containerTransportVehicle ? ' (контейнеровоз)':''),
                });
                this.form.vehicleMake.value = response.data.model.id;
                if (response.data.bodyType) {
                  this.form.bodyType.id = response.data.bodyType.id;
                }
                this.$nextTick(() => {
                  this.getVehicleModelData(response.data.model.id);
                });
              } else {
                this.checkVehicleDone = true;
                this.existingVehicle = false;
                this.showInfo('Данное транспортное средство не найдено');
                this.vehicleModelsList({
                  limit: 10,
                });
              }
            }
            this.loading = false;
          } else if (this.checkVehicleDone) {
            this.checkVehicleDone = false;
          }
        } else {
          this.loading = true;
          this.checkVehicleDone = false;
          const response = await contractorsTransportVehicleRead(this.$store.state.user.ownerId, this.transportVehiclesId);
          if (response && response.status === 200) {
            this.checkVehicleDone = true;
            this.existingVehicle = false;
            this.form.vehicleMake.options = [];
            this.form.vehicleMake.options.push( {
              value: response.data.model.id,
              text: response.data.model.name + (response.data.model.containerTransportVehicle ? ' (контейнеровоз)':''),
            } );
            this.form.vehicleMake.value = response.data.model.id;
            if (response.data.bodyType) {
              this.form.bodyType.id = response.data.bodyType.id;
            }
            this.isOwn = this.vehicleIsOwn;
            this.$nextTick(() => {
              this.getVehicleModelData(response.data.model.id);
            });
          }
        }
      }
    },
    async getVehicleModelData(modelId) {
      this.loading = true;
      if (modelId) {
        const response = await vehiclesModelRead(modelId);
        if (response && response.status === 200) {
          this.form.bodyVolume = response.data.bodyVolume;
          this.form.weightAllowed = response.data.loadCapacity;
          this.form.bodyHeight = response.data.bodyHeight;
          this.form.bodyWidth = response.data.bodyWidth;
          this.form.bodyDepth = response.data.bodyDepth;
          this.form.brand = response.data.brand;
          this.form.vehicleType.id = response.data.type.id;
        }
      }
      this.loading = false;
    },
    async contractorsRoutesLegitDriversList(fio) {
      this.loading = true;
      const params = {limit: 100};
      if (fio) {
        params.fio = fio;
      }
      const response = await contractorsRoutesLegitDriversList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.legitDrivers.options = response.data.items.map((item) => {
          let text = null;
          if (item.driver.patronymic !== undefined) {
            text = `${item.driver.surname} ${item.driver.name} ${item.driver.patronymic}`;
          } else {
            text = `${item.driver.surname} ${item.driver.name}`;
          }
          return {value: item.id, text: text};
        });
      }
      this.loading = false;
    },
    onVehicleMakeChange(value) {
      this.getVehicleModelData(value);
    },
    async contractorsTransportVehicleRead(contractorId, transportVehicleId) {
      this.loading = true;
      const response = await contractorsTransportVehicleRead(contractorId, transportVehicleId);
      if (response && response.status === 200) {
        this.isOwn = response.data.isOwn;
        this.vehicleIsOwn = response.data.isOwn;
        this.vehicle.id = response.data.vehicle.id;
        this.vehicle.name = response.data.vehicle.name;
        this.form.STS = response.data.vehicle.certificateRegistrationVehicleSeriesNumber;
        this.vehicle.STS = response.data.vehicle.certificateRegistrationVehicleSeriesNumber;
        this.fieldsTriggeringCheckVehicleOldValues.STS = response.data.vehicle.certificateRegistrationVehicleSeriesNumber;
        this.vehicle.status = response.data.vehicle.status;
        if (response.data.stsScreen) {
          this.form.stsScreen = response.data.stsScreen;
        }
        if (response.data.stsScreenBack) {
          this.form.stsScreenBack = response.data.stsScreenBack;
        }
        if (response.data.contractorDriver) {
          this.legitDrivers.value = response.data.contractorDriver.id;
          this.form.legitDriver = this.legitDrivers.value;
        }
        this.form.foreignRegistrationNumber = response.data.vehicle.foreignRegistrationNumber;
        this.form.vehicleMake.options = [];
        this.form.vehicleMake.options.push({
          value: response.data.vehicle.model.id,
          text: response.data.vehicle.model.name + (response.data.vehicle.model.containerTransportVehicle ? ' (контейнеровоз)':''),
        });
        this.form.vehicleMake.value = response.data.vehicle.model.id;
        this.onVehicleMakeChange(response.data.vehicle.model.id);
        this.form.registrationNumber = response.data.vehicle.registrationNumber;
        this.vehicle.registrationNumber = response.data.vehicle.registrationNumber;
        this.fieldsTriggeringCheckVehicleOldValues.registrationNumber = response.data.vehicle.registrationNumber;
        if (response.data.vehicle.bodyType) {
          this.form.bodyType.id = response.data.vehicle.bodyType.id;
        }
        if (response.data.vehicle.contractorOwner) {
          this.owner.options.push({
            value: response.data.vehicle.contractorOwner.id,
            text: response.data.vehicle.contractorOwner.name,
          });
          this.owner.value = response.data.vehicle.contractorOwner.id;
        }
      }
      this.loading = false;
    },
    async vehicleModelsList(params) {
      this.loading = true;
      const response = await vehicleModelsList(params);
      if (response && response.status === 200) {
        this.form.vehicleMake.options = response.data.items.map((item) => {
          return {value: item.id, text: item.name + (item.containerTransportVehicle ? ' (контейнеровоз)':'')};
        });
      }
      this.loading = false;
    },
    async vehicleModelsTypesList() {
      this.loading = true;
      const response = await vehicleModelsTypesList();
      if (response && response.status === 200) {
        this.vehicleTypes = this.vehicleTypes.concat(
          response.data.items.map((item) => {
            if (item.name === 'Тягач') {
              this.vehicleTypeWithOutBodyId = item.id;
            }
            return {value: item.id, text: item.name};
          }),
        );
      }
      this.loading = false;
    },
    async vehicleModelsBodyTypesList() {
      this.loading = true;
      const response = await vehicleModelsBodyTypesList({
        for_delivery: false,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.bodyTypes = this.bodyTypes.concat(
          response.data.items.map((item) => {
            return {value: item.id, text: item.name};
          }),
        );
      }
      this.loading = false;
    },
    vehicleMakeRemote(query) {
      if (query !== '') {
        this.vehicleModelsList({
          query: query,
          limit: 100,
        });
      } else {
        this.vehicleModelsList({
          limit: 10,
        });
      }
    },
    async contractorRemote(query) {
      this.loading = true;
      const response = await contractorsRelatedOwnersList(this.$store.state.user.ownerId, {
        name: query,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.owner.options = response.data.items.map((item) => {
          return {value: item.owner.id, text: item.owner.name};
        });
      }
      this.loading = false;
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        const response = await contractorsTransportVehicleUpdate(this.$store.state.user.ownerId, this.transportVehiclesId, this.formStr);
        if (response && response.status === 200) {
          this.showSuccess('Транспортное средство обновлено');
          this.$router.push({path: '/transport-vehicles'});
        }
        this.loading = false;
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else return !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    async documentCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.form[key] = response.data;
        this.showSuccess('Документ загружен');
      }
      this.loading = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    uploadFile(key) {
      const file = this.form[key];
      if (file) {
        if (file.size >= 15 * 1024 * 1024) {
          this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
        } else {
          this.getBase64(file).then((data) => {
            const upload = {
              base64file: data,
              originalName: file.name,
            };
            this.documentCreate(key, upload);
          });
        }
      }
    },
    isVehicleTypeForOgr(vt, vm) {
      const bodyType = this.vehicleTypes.find((type)=>{
        return type.value === this.form.vehicleType.id;
      });
      if (this.form.foreignRegistrationNumber) {
        return true;
      }
      if (bodyType.text === 'Прицеп' || bodyType.text === 'Полуприцеп') {
        if (/^[а-яА-Яё\-\s]{1}(?!0{3})[0-9]{3}[а-яА-Яё\-\s]{2}[0-9]{2,3}$/.test(this.form.registrationNumber)) {
          return true;
        }
      } else {
        if (/^[а-яА-Яё\-\s]{2}(?!0{4})[0-9]{4}[0-9]{2,3}$/.test(this.form.registrationNumber)) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.is-valid-error {
  border: 1px solid red !important;
  height: 100%;
}

.is-valid-success {
  border: 1px solid var(--success) !important;
  height: 100%;
}
</style>

